/** localstorage key to track position */
export const readingPositionKey = "modern_read_history"

/**
 * Returns the reading positions for the user's local history.
 * Schema looks as follows
 * {
 *   [slug]: {
 *      percentage: number
 *      lastTitle: string
 *   }
 * }
 */
export const lookupReadingPositions = () => {
  if (typeof window === `undefined`) {
    return
  }

  const result = localStorage.getItem(readingPositionKey)
  if (result === undefined || result === null) {
    localStorage.setItem(readingPositionKey, "{}")
    return {}
  }
  return JSON.parse(result)
}

/**
 * Update stored track of visitors reading progress via percentage
 * @param {string} title title of the post being read
 * @param {number} percentage percentage of the post that has been read
 */
export const updateReadingPercentage = (title, percentage) => {
  if (typeof window === `undefined`) {
    return
  }

  const currentPositions = lookupReadingPositions()
  const result = {
    ...currentPositions,
    [title]: {
      ...(currentPositions[title] || {}),
      percentage,
    },
  }
  if (
    currentPositions[title] &&
    currentPositions[title].percentage > percentage
  ) {
    return
  }
  console.debug("percentage change", percentage)
  localStorage.setItem(readingPositionKey, JSON.stringify(result))
}

/**
 * Update stored track of the visitors reading progress via last read section
 * @param {string} title title of the post being read
 * @param {number} lastTitle percentage of the post that has been read
 */
export const updateLastReadTitle = (title, lastTitle) => {
  if (typeof window === `undefined`) {
    return
  }

  const currentPositions = lookupReadingPositions()
  const result = {
    ...currentPositions,
    [title]: {
      ...(currentPositions[title] || {}),
      lastTitle,
    },
  }
  console.log("title change", lastTitle)
  localStorage.setItem(readingPositionKey, JSON.stringify(result))
}
