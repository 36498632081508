/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState } from "react"

export const ReadingProgress = ({ target, initialProgress, scrollUpdate }) => {
  const [readingProgress, setReadingProgress] = useState(initialProgress || 0)
  const scrollListener = () => {
    if (!target || !target.current) {
      return
    }

    const element = target.current
    const totalHeight =
      element.clientHeight + element.offsetTop - window.innerHeight
    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    let scrollPercentage
    if (windowScrollTop === 0) {
      scrollPercentage = 0
    }

    if (windowScrollTop > totalHeight) {
      scrollPercentage = 100
    }

    if (scrollPercentage === undefined) {
      scrollPercentage = (windowScrollTop / totalHeight) * 100
    }

    setReadingProgress(scrollPercentage)
    if (scrollPercentage > 0) {
      scrollUpdate(scrollPercentage)
    }
  }

  useEffect(() => {
    if (target) {
      window.addEventListener("scroll", scrollListener)
      return () => window.removeEventListener("scroll", scrollListener)
    }
  })

  return (
    <div
      sx={{
        backgroundColor: "ranglered",
        height: "5px",
        top: 0,
        position: "sticky",
      }}
      style={{ width: `${readingProgress}%` }}
    />
  )
}
