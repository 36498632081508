/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { lookupReadingPositions } from "../util/readingPosition"
import { ReadingProgress } from "./ReadingProgress"

const transformIn = {
  transform: "translateX(0px)",
  transition: "transform 0.5s ease-out",
}
const transformOut = {
  transform: ["none", "translateX(10px)"],
  transition: ["none", "transform 0.5s ease-out"],
}
const hoverStyles = {
  "a:hover": {
    opacity: 1,
    li: {
      h3: { ...transformOut },
      h4: { ...transformOut },
      p: { ...transformOut },
      picture: {
        opacity: [0.8],
        transition: ["opacity 0.3s ease"],
      },
    },
  },
}
const bodyWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: ["1.5rem 1.25rem", "1.5rem 1.25rem", "3rem"],
  marginBottom: "1rem",
  borderBottom: "1px solid #ebebeb",
  minWidth: ["auto", "70%"],
}

export default function TopicLinks(props) {
  const readingPositions = lookupReadingPositions()

  return (
    <ul
      sx={{
        display: "flex",
        flexDirection: props.vertical ? ["row", "column"] : "row",
        justifyContent: props.vertical
          ? ["space-around", "space-between"]
          : "space-around",
        width: props.vertical ? ["100%", "40%"] : "100%",
        marginBottom: "2rem",
        marginLeft: 0,
        ...hoverStyles,
      }}
    >
      {props.data.map((topic) => {
        const { node } = topic
        const comingSoon = node.frontmatter.coming_soon
        return (
          <Link
            sx={{
              width: props.vertical ? ["70%", "auto"] : ["70%", "49%", "33%"],
              margin: props.vertical ? [1, 2] : 1,
            }}
            key={node.fields.slug}
            to={
              comingSoon
                ? undefined
                : node.fields.levels.L2
                ? `/${node.fields.levels.L1}/${node.fields.levels.L2}/${node.fields.slug}`
                : `/${node.fields.levels.L1}/${node.fields.slug}`
            }
          >
            <li
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                opacity: "inherit",
                marginBottom: 0,
                border: "1px solid",
                borderColor: "lighttext",
              }}
            >
              <div
                sx={{
                  width: ["100%", "auto"],
                  minWidth: ["auto", "100%"],
                  height: props.vertical ? ["33vh", "20vh"] : "33vh",
                  minHeight: ["auto", "0"],
                  overflow: "hidden",
                  backgroundColor: "text",
                  img: {
                    objectFit: "cover",
                    objectPosition: "50% 50%",
                    minHeight: "100%",
                  },
                  picture: {
                    opacity: 1,
                    transition: "opacity 0.3s ease",
                  },
                }}
              >
                <Img
                  fluid={node.frontmatter.hero_image.childImageSharp.fluid}
                  alt={node.frontmatter.title}
                />
              </div>
              <ReadingProgress
                initialProgress={
                  readingPositions &&
                  readingPositions[node.frontmatter.title] &&
                  readingPositions[node.frontmatter.title].percentage
                }
              />
              {comingSoon ? (
                <div sx={bodyWrapperStyle}>
                  <Styled.h3
                    sx={{
                      ...transformIn,
                      marginBottom: ["1rem", "1rem", "2rem"],
                    }}
                  >
                    {node.frontmatter.title}
                  </Styled.h3>
                  <Styled.p sx={transformIn}>Coming Soon.</Styled.p>
                </div>
              ) : (
                <div sx={bodyWrapperStyle}>
                  <Styled.h3
                    sx={{
                      ...transformIn,
                      marginBottom: ["1rem", "1rem", "2rem"],
                    }}
                  >
                    {node.frontmatter.title}
                  </Styled.h3>
                  {node.frontmatter.date && (
                    <h4 sx={transformIn}>{node.frontmatter.date}</h4>
                  )}
                  <Styled.p sx={transformIn}>
                    {node.frontmatter.description}
                  </Styled.p>
                </div>
              )}
            </li>
          </Link>
        )
      })}
    </ul>
  )
}
