/** @jsx jsx */
import { jsx, Styled, Flex } from "theme-ui"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import TopicLinks from "../components/TopicLink"

export default function Hub(props) {
  const data = props.data.mdx
  const linkData = props.data.allMdx.edges

  return (
    <div>
      <Layout page="content">
        <div>
          <article
            sx={{
              marginBottom: "8rem",
              display: ["", "flex"],
              flexDirection: ["", "column"],
            }}
          >
            <figure
              sx={{
                minHeight: ["200px", "300px"],
                height: "50vh",
                width: "100%",
                margin: 0,
                img: {
                  marginBottom: 0,
                  objectFit: "cover",
                  minWidth: ["auto", "100%"],
                },
              }}
            >
              <Img
                fluid={data.frontmatter.hero_image.childImageSharp.fluid}
                alt={data.frontmatter.title}
              />
            </figure>
            <Flex
              sx={{
                flexDirection: ["column", "row"],
              }}
            >
              <div sx={{ width: ["100%", "70%"] }}>
                <div
                  sx={{
                    padding: ["1.5rem 1.25rem", "2rem 0", "3rem 0"],
                    width: "100%",
                    maxWidth: "768px",
                    margin: "0 auto",
                    textAlign: ["", "center"],
                  }}
                >
                  <Styled.h1
                    sx={{
                      marginBottom: "0.66rem",
                      maxWidth: ["auto", "500px"],
                      margin: "0 auto 0.66rem auto",
                    }}
                  >
                    {data.frontmatter.title}
                  </Styled.h1>
                </div>
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                    padding: ["0 1.25rem", "0 2rem"],
                    margin: "0 auto",
                    maxWidth: ["auto", "800px"],
                  }}
                >
                  <MDXRenderer>{data.body}</MDXRenderer>
                </Flex>
              </div>

              <TopicLinks data={linkData} vertical />
            </Flex>
          </article>
        </div>
      </Layout>
    </div>
  )
}

//dynamic page query, must occur within each post context
//$slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        hero_image {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
    allMdx(
      filter: { fields: { levels: { L1: { eq: $slug }, L2: { eq: null } } } }
      sort: { order: ASC, fields: fields___priority }
    ) {
      edges {
        node {
          fields {
            slug
            levels {
              L1
              L2
            }
          }
          id
          frontmatter {
            title
            hero_image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
            coming_soon
          }
        }
      }
    }
  }
`
